import { Outlet, Link } from "react-router-dom";

function App() {

    return (
        <div className="container-fluid" id="page-content">
            <div className="row align-items-center" id="page-nav">
                <div className="col-sm-4"></div>
                <div className="col-sm-4" id="nav-center">
                    <h3 id="nav-title">
                        <Link to="/">PORTFOLIO</Link>
                    </h3>
                </div>
                <div className="col-sm-4" id="nav-right">
                </div>
            </div>
            <div className="row align-items-center" id="page-content">
                <Outlet />
            </div>
        </div>
    );
}

export default App;
