function SocialLinks() {
    return (
        <div className="link-window">
            <div className="link-container">
                <a href="https://www.linkedin.com/in/illack-chiti/">LinkedIn</a>
            </div>
            <div className="link-container">
                <a href="https://gitlab.com/illackchiti">GitLab</a>
            </div>
            <div className="link-container">
                <a href="https://www.degreeapprentice.info">MySaas</a>
                {/* add new page which links to more saas I build */}
            </div>
            <div className="link-container">
                <a href="https://leetcode.com/u/illack/">LeetCode</a>
            </div>
            <div className="link-container">
                <a href="https://www.youtube.com/channel/UCfcnRgIuCi5mtYJCCPnbrkQ">YouTube</a>
            </div>
            
        </div>
    );
}

function TechLogos(props) {
    const logo_image_path = process.env.PUBLIC_URL + '/static/images/techlogos/' + props.logoName + '.png';


    return (
        <img id="tech-logo" src={logo_image_path} alt={logo_image_path} />
    );

}

function MyIntro() {
    const headshot_path = process.env.PUBLIC_URL + '/static/images/illack_headshot.png';
    const image_names = ['python_logo','java_logo', 'mysql_logo',
                        'aws_logo', 'gitlab_logo', 'react_logo'];

    return (
        <div className="intro-window">
            <div className="info-container">
                <img src={headshot_path} alt='illack-chiti' />
                <p className="about-me">
                    ILLACK CHITI<br></br>
                    BSc SOFTWARE ENGINEER, BEng CHEMICAL ENGINEER<br></br>
                    <strong>2 years Software/Data Engineering & Cloud Computing</strong>
                </p>
                <div>
                    {image_names.map((image_name) => <TechLogos logoName={image_name} />)}
                </div>
            </div>
        </div>
    );
}


function Portfolio() {
    return (
        <>
            <div className="col-sm-6" id="content-win-1-l">
                < SocialLinks />
            </div>
            <div className="col-sm-6" id="content-win-1-r">
                < MyIntro />
            </div>
        </>
    );
}

export default Portfolio;